import Vue from 'vue';
import VueRouter from 'vue-router';

import HomeView from '@/views/HomeView';
import PlatformView from '@/views/PlatformView';
import GameView from '@/views/GameView';
import GamePlayView from '@/views/GamePlayView';
import TagView from "@/views/TagView";
import SubscribeView from "@/views/SubscribeView";
import Landing from "@/views/Landing.vue";
import Auth from "@/views/Auth.vue";

Vue.use(VueRouter);

const routes = [
	// Home
	{path: '/platform/:platform', name: 'platform', component: PlatformView},
	{path: '/tag/:tag', name: 'tag', component: TagView},
	{path: '/game/:id', name: 'game', component: GameView},
	{path: '/play/:id', name: 'play', component: GamePlayView},
	{path: '/subscribe', name: 'subscribe', component: SubscribeView},
	{path: '/landing', name: 'landing', component: Landing},
	{path: '/auth', name: 'auth', component: Auth},
	{path: '/', name: 'home', component: HomeView},
];

const router = new VueRouter({
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	routes,
	mode: 'history'
});

router.beforeEach((to, from, next) => {
    let click_id = getQuery('click_id');

    if (click_id) {
        setCookie('click_id', click_id, 1);
    }
    
    if (to.name === 'auth' || to.name === 'landing' || to.name === 'home') {
        next(); return;
    }

    axios.get('/games/t2_profile').then(({data}) => {
        if (!data.msisdn || !data.subscription) {
            location.href = '/games/t2_subscribe';
        } else {
            next(); return;
        }
    })
});




function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function getQuery(param) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
}


export default router;
