<template>
	<div></div>
</template>

<script>
	var globalIframe, globalUrl;
	
	export default {
		data() {
			return {
				id: this.$route.params.id,
				game: null,
				auth: window.auth
			}
		},
		
		created() {
			this.axios.get('/games/' + this.id + '/').then(({data}) => {
				this.game = data;
				
				this.initIframe(this.game);
			});
		},
		
		methods: {
			initIframe(game) {
				var iframe = document.createElement('iframe');
				iframe.id = 'game-frame';
				iframe.className = 'game-frame';
				iframe.src = game.api_url;
				iframe.setAttribute('frameborder', '0');
				iframe.setAttribute('name', window.location.href);

				var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
				var eventer = window[eventMethod];
				var messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message';
				eventer(messageEvent, (e) => {
					switch (e.data.type) {
						case 'send':
							this.sendScore({
								type: e.data.label,
								level: e.data.level,
								score: e.data.score
							});
							break;
					}
				}, false);
				document.getElementsByTagName('body')[0].appendChild(iframe);
				globalIframe = iframe;
			},
			playGame(url) {
				globalUrl = url;
				globalIframe.contentWindow.postMessage({
					message: 'callbackExecuted'
				}, url);
			},
			sendScore(obj) {
				if (this.game.for_raiting) {
					this.axios.post('/games/' + this.id + '/scores/', {score: obj.score});
				}
			}
		}
	}
</script>

<style scoped>

</style>
