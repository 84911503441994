<template>
	<div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},

		created() {
			location.href = '/games/t2_subscribe';
		},
	}
</script>

<style scoped>

</style>
