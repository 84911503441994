<template>
	<div class="footer-main">
		<footer class="footer">
			<div class="wrapper">
				<div class="footer_row">
					<div class="footer_text">Игровой портал</div>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
	import moment from 'moment';

	export default {
		methods: {
			year() {
				return moment().format('Y')
			}
		},
	}
</script>
