<template>
	<div v-if="game">
		<div class="game" v-if="game">
			<div class="wrapper">
				<div class="game_row">
					<div class="game_image">
						<img :src="game.logo_image" alt="">
					</div>
					<div class="game_content">
						<div>
							<div class="game_title bold">{{ game.name }}</div>
							
							<div class="game_image __mobile">
								<img :src="game.logo_image" alt="">
							</div>
							
							<div class="game_breadcrumbs">
								<router-link v-if="game.platform_id === 1" to="/platform/android">Android игры</router-link>
								<router-link v-else-if="game.platform_id === 2" to="/platform/online">Онлайн игры</router-link>
								<span>/</span>
								<router-link v-if="game.platform_id === 1" :to="'/platform/android/?genre=' + game.genre_id">{{ game.genre_name }}</router-link>
								<router-link v-if="game.platform_id === 2" :to="'/platform/online/?genre=' + game.genre_id">{{ game.genre_name }}</router-link>
								<router-link v-if="game.platform_id === 4" :to="'/platform/free/?genre=' + game.genre_id">{{ game.genre_name }}</router-link>
							</div>
							
							<div class="game_lang">
								<div v-if="game.localiztion === 'RU'">Язык: русский</div>
								<div v-else-if="game.localiztion === 'EN'">Язык: английский</div>
							</div>
						</div>
						
						<div class="game_buttons">
							<a v-if="game.api_url" :href="'/play/' + game.id" class="button __type-2" @click="openGame">Играть</a>
							<a v-else :href="game.path" class="button __type-2" @click="openGame">
								<span v-if="game.platform_id === 1">Скачать игру</span>
								<span v-else>Играть</span>
							</a>
							
							<div class="game_share" v-if="game">
								<span class="game_share-span">Вызов другу</span>
								<yandex-share :services="['odnoklassniki', 'vkontakte','telegram']" :limit="0" popup-outer :title="share.title" :description="share.description" />
							</div>
						</div>
					</div>
				</div>
				
				<div class="game_tags">
					<router-link :to="'/tag/' + tag.id" v-for="tag in game.tags">{{ tag.name }}</router-link>
				</div>
				
				<div class="game_logos">
					<div v-for="screenshot in game.screenshots" class="game_logo">
						<img :src="screenshot" alt="">
					</div>
				</div>
				
				<div class="game_row-main">
					<div class="game_description" v-html="game.description"></div>
					
					<div class="game_score" v-if="/*scores.length && */game.for_raiting">
						<div class="game_score-title">Лучшие игроки</div>
						
						<div class="game_score-table">
							<div class="game_score-tr" v-for="(score, num) in scores" :key="score.id">
								<div class="game_score-td">{{ num + 1 }}</div>
								<div class="game_score-td">
									<div class="game_score-main">
										<img :src="score.picture" alt="">
										<span>{{ score.name }}</span>
									</div>
								</div>
								<div class="game_score-td">{{ score.score }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import YandexShare from '@cookieseater/vue-yandex-share';
	import MainSlider from "@/components/MainSlider/MainSlider";
	
	export default {
		components: {
			YandexShare,
			MainSlider,
		},
		data() {
			return {
				game: null,
				gameId: this.$route.params.id,
				scores: [],
				auth: window.auth,
				share: {
					title: '',
					description: 'Играй в любые игры без ограничений на портале Tele2'
				}
			}
		},
		created() {
			this.axios.get('/games/' + this.gameId + '/').then(({data}) => {
				this.share.title = 'Давай сыграем вместе в ' + data.name;
				
				this.game = data;
			});
			
			this.axios.get('/games/' + this.gameId + '/scores/').then(({data}) => {
				if (data.data.length) {
					this.scores = data.data;
				}
			});
		},
		methods: {
			openGame(e) {
				
			}
		}
	}
</script>

<style scoped>

</style>
