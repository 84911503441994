<template>
    <div class="landing" @click="goToPage">
        <img src="/img/games.jpg" alt="">
        <img src="/img/games2.png" alt="">
        <br>
        <a href="/games/t2_subscribe" class="m-button">Играть</a>
    </div>
</template>
<script>
export default {
    methods: {
        goToPage() {
            location.href = '/games/t2_subscribe';
        }
    }
}
</script>
<style scoped>
    .landing {
        padding: 20px;
        text-align: center;
        position: fixed;
        top: 0;
        left:0;
        right: 0;
        bottom: 0;
        background: #fff;
        z-index: 99999;

        @media screen and (max-width: 1000px) {
            padding: 0;
        }
        
        .button {
            @media screen and (max-width: 1000px) {
                
            }
        }
    }
    .landing img {
        margin-bottom: 20px;
        width: 700px;
        max-width: 100%;
        
        &:nth-child(1) {
            @media screen and (max-width: 1000px) {
                display: none;
            }
        }
        &:nth-child(2) {
            display: none;
            
            @media screen and (max-width: 1000px) {
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .m-button {
        color: #fff;
        font-size: 20px;
        background: #ff5923;
        padding: 15px 30px;
        border-radius: 30px;
        position: fixed;
        bottom: 100px;
        left: 50%;
        z-index: 100;
        transform: translateX(-50%);
        text-decoration: none;
    }
</style>
