<template>
    <div>
        <header class="header">
            <div class="wrapper">
                <div class="header_row">
                    <router-link :to="{name: 'home'}" class="header_name">
                        Росконтент
                    </router-link>

                    <div class="header_ico" @click="showMenu = true">
                        <i></i>
                        <i></i>
                        <i></i>
                    </div>
                </div>
            </div>
        </header>

        <div class="menu">
            <div class="wrapper">
                <div class="menu_list">
                    <router-link v-if="!isiOS()" to="/platform/android">Android</router-link>
                    <router-link to="/platform/online">Онлайн</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'underscore';
import $ from 'jquery';

export default {
    data() {
        return {
            auth: window.auth,
            showAuthForm: false,
            showMenu: false,
            showNumberForm: false,
        }
    },

    created() {
       
    },

    mounted() {

    },

    methods: {
        isiOS() {
            return [
                    'iPad Simulator',
                    'iPhone Simulator',
                    'iPod Simulator',
                    'iPad',
                    'iPhone',
                    'iPod'
                ].includes(navigator.platform)
                || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        },
        checkUnsubscribe() {
            axios.get('/games/profile').then(({data}) => {
                if (!data.subscription) {
                    location.reload();
                } else {
                    setTimeout(() => {
                        this.checkUnsubscribe();
                    }, 500);
                }
            })
        }
    }
}
</script>
